import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import StepProgress from '../../components/StepProgress';
import UserAccount from './UserAccount';

import { Container } from './styles';
import { useAuth } from '../../hooks/auth';
import { useProduct } from '../../hooks/product';
import NotFound from '../../components/NotFound';
import { useLoading } from '../../hooks/loading';

const Default: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { isLoading } = useLoading();
  const { productLoaded } = useProduct();
  const history = useHistory();

  useEffect(() => {
    if (productLoaded && isAuthenticated) {
      history.push('/pagamento');
    }
  }, [isAuthenticated, history, productLoaded]);

  return (
    <Container>
      <header>
        <img
          src="https://poxalulu-api.s3.sa-east-1.amazonaws.com/site/logo/logo-poxalulu.svg"
          alt="Poxalulu"
        />
      </header>

      {!isLoading && !productLoaded && <NotFound />}
      {productLoaded && (
        <>
          <StepProgress
            steps={[
              'Criar conta',
              'Forma de pagamento',
              'Informações da compra',
            ]}
            currentStep={0}
          />

          <UserAccount />
        </>
      )}

      <footer>
        <span className="contact">
          Em caso de problemas, entre em contato conosco enviando um e-mail para{' '}
          <a href="mailto:suporte@poxalulu.com.br">suporte@poxalulu.com.br</a>.
        </span>

        <div>
          <span>©2022 Poxalulu - Todos os Direitos Reservados.</span>

          <span>
            Desenvolvido por{' '}
            <a
              href="https://www.pxtecnologia.com.br"
              target="_blank"
              rel="noreferrer"
            >
              PX Tecnologia
            </a>
            .
          </span>
        </div>
      </footer>
    </Container>
  );
};

export default Default;
