import React, { useState, useEffect, useRef } from 'react';
import { MdClose } from 'react-icons/md';

import { ToastMessage, useToast } from '../../../hooks/toast';

import { Container, Life } from './styles';

interface ToastProps {
  message: ToastMessage;
  style: Record<string, unknown>;
}

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const toastRef = useRef<HTMLDivElement>(null);
  const lifeRef = useRef<HTMLDivElement>(null);
  const counterRef = useRef(0);

  const [isHovered, setIsHovered] = useState(false);

  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isHovered) {
        counterRef.current += 5;
      }

      if (lifeRef.current) {
        lifeRef.current.style.width = `${counterRef.current / 50}%`;
      }

      if (counterRef.current >= 5000) {
        removeToast(message.id);
      }
    }, 1);

    return () => {
      clearInterval(timer);
    };
  }, [isHovered, message.id, removeToast]);

  useEffect(() => {
    const currentToast = toastRef.current;

    if (currentToast) {
      currentToast.addEventListener('mouseover', () => {
        setIsHovered(true);
      });
      currentToast.addEventListener('mouseleave', () => {
        setIsHovered(false);
      });
    }

    return () => {
      if (currentToast) {
        currentToast.removeEventListener('mouseover', () => {
          setIsHovered(true);
        });
        currentToast.removeEventListener('mouseleave', () => {
          setIsHovered(false);
        });
      }
    };
  }, []);

  return (
    <Container ref={toastRef} type={message.type} style={{ ...style }}>
      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button onClick={() => removeToast(message.id)} type="button">
        <MdClose size={18} />
      </button>

      <Life ref={lifeRef} />
    </Container>
  );
};

export default Toast;
