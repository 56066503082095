import { format } from 'date-fns';
import React, { useCallback, useEffect } from 'react';
import { MdContentCopy, MdInfo } from 'react-icons/md';
import { useQRCode } from 'next-qrcode';
import { useHistory } from 'react-router-dom';

import { useCharge } from '../../hooks/charge';
import { useToast } from '../../hooks/toast';

import Summary from '../Default/Summary';

import { Container as DefaultContainer } from '../Default/styles';
import {
  BilletDetails,
  BoxInfo,
  Container,
  PixDetails,
  CreditCardDetails,
  PaymentExt,
} from './styles';
import StepProgress from '../../components/StepProgress';
import { useProduct } from '../../hooks/product';
import { useAuth } from '../../hooks/auth';

const ChargeDetails: React.FC = () => {
  const { charge } = useCharge();
  const { addToast } = useToast();
  const { Image } = useQRCode();
  const { productLoaded } = useProduct();
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!productLoaded || !isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated, history, productLoaded]);

  const handleCopy = useCallback(() => {
    const permissionName = 'clipboard-write' as PermissionName;

    navigator.permissions.query({ name: permissionName }).then(({ state }) => {
      if (state === 'granted' || state === 'prompt') {
        if (!charge.qr_code) {
          return;
        }

        navigator.clipboard.writeText(charge.qr_code).then(
          () => {
            addToast({
              title: 'Sucesso!',
              description: 'Código pix copiado com sucesso!',
              type: 'success',
            });
          },
          () => {
            addToast({
              title: 'Erro!',
              description: 'Ocorreu um erro ao copiar o código pix!',
              type: 'error',
            });
          },
        );
      }
    });
  }, [addToast, charge.qr_code]);

  return (
    <DefaultContainer>
      <header>
        <img
          src="https://poxalulu-api.s3.sa-east-1.amazonaws.com/site/logo/logo-poxalulu.svg"
          alt="Poxalulu"
        />
      </header>

      <StepProgress
        steps={['Criar conta', 'Forma de pagamento', 'Informações da compra']}
        currentStep={2}
      />

      <Summary>
        <Container>
          <div>
            <h2>Informações da compra</h2>

            {charge.type === 'pix' && (
              <>
                <PixDetails>
                  <p>
                    Seu código Pix foi gerado com sucesso! 🤩
                    <br />O código pix irá expirar em <strong>24 horas</strong>,
                    portanto, efetue o pagamento o quanto antes para ter acesso
                    a nossa Plataforma.
                  </p>

                  {charge.qr_code && (
                    <Image
                      text={charge.qr_code}
                      options={{
                        type: 'image/jpeg',
                        quality: 1,
                        level: 'L',
                        margin: 3,
                        scale: 4,
                        width: 250,
                        color: {
                          dark: '#000000',
                          light: '#FFFFFF',
                        },
                      }}
                    />
                  )}
                </PixDetails>

                <PaymentExt>
                  <span>Código copia e cola</span>

                  <p>{charge.qr_code}</p>

                  <button type="button" onClick={handleCopy}>
                    <MdContentCopy size={20} />
                  </button>
                </PaymentExt>

                <iframe
                  width="400"
                  height="225"
                  src="https://www.youtube.com/embed/ujpL9hjxNAA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />

                <BoxInfo>
                  <div>
                    <MdInfo size={24} />
                  </div>

                  <p>
                    Ao adquirir o plano via Pix, a liberação do acesso a
                    plataforma ocorrerá após a confirmação do pagamento. Isso
                    poderá levar alguns minutos. Assim que seu acesso for
                    liberado, você receberá um e-mail com a confirmação.
                  </p>
                </BoxInfo>
              </>
            )}

            {charge.type === 'billet' && (
              <>
                <BilletDetails>
                  <p>
                    Seu boleto foi gerado com sucesso! 🤩
                    <br />
                    Para ter acesso ao seu plano, pague o boleto até o dia{' '}
                    <strong>
                      {charge.boleto_expiration_date &&
                        format(
                          new Date(charge.boleto_expiration_date),
                          'dd/MM/yyyy',
                        )}
                    </strong>{' '}
                    e comece a estudar com a gente.
                  </p>

                  <iframe
                    width="400"
                    height="225"
                    src="https://www.youtube.com/embed/ujpL9hjxNAA"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />

                  <div>
                    <a
                      href={charge.boleto_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Visualizar boleto
                    </a>
                  </div>
                </BilletDetails>

                <BoxInfo>
                  <div>
                    <MdInfo size={24} />
                  </div>

                  <p>
                    Pagamentos efetuados via boleto podem levar até{' '}
                    <strong>
                      3 dias úteis a partir da data de pagamento para serem
                      confirmados
                    </strong>
                    . Assim que o pagamento for confirmado, você receberá um
                    e-mail e poderá acessar a Plataforma Poxalulu normalmente.
                  </p>
                </BoxInfo>
              </>
            )}

            {charge.type === 'credit_card' && (
              <CreditCardDetails>
                <p>
                  Seu pagamento foi confirmado! 🤩
                  <br />O pagamento referente ao seu pedido foi confirmado e
                  você já possui acesso à Plataforma Poxalulu!
                </p>

                <iframe
                  width="400"
                  height="225"
                  src="https://www.youtube.com/embed/ujpL9hjxNAA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />

                <div>
                  <a href="https://plataforma.poxalulu.com.br">
                    Acessar plataforma
                  </a>
                </div>
              </CreditCardDetails>
            )}
          </div>
        </Container>
      </Summary>

      <footer>
        <span className="contact">
          Em caso de problemas, entre em contato conosco enviando um e-mail para{' '}
          <a href="mailto:suporte@poxalulu.com.br">suporte@poxalulu.com.br</a>.
        </span>

        <div>
          <span>©2022 Poxalulu - Todos os Direitos Reservados.</span>

          <span>
            Desenvolvido por{' '}
            <a
              href="https://www.pxtecnologia.com.br"
              target="_blank"
              rel="noreferrer"
            >
              PX Tecnologia
            </a>
            .
          </span>
        </div>
      </footer>
    </DefaultContainer>
  );
};

export default ChargeDetails;
