import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';

import Input from '../../../../components/Input';
import ForgotPassword from './ForgotPassword';

import { Container, Forgot } from './styles';
import { useAuth } from '../../../../hooks/auth';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Button from '../../../../components/Button';
import { useLoading } from '../../../../hooks/loading';
import { useModal } from '../../../../hooks/modal';

interface IUserLoginFormData {
  email: string;
  password: string;
}

interface IUserLoginProps {
  defaultEmail?: string;
}

const UserLogin: React.FC<IUserLoginProps> = ({ defaultEmail }) => {
  const { addToast } = useToast();
  const { addModal } = useModal();
  const { login } = useAuth();
  const { startLoading, stopLoading } = useLoading();
  const history = useHistory();

  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const recaptchaRef = useRef<any>(null);
  const formRef = useRef<FormHandles>(null);

  const handleDOMChange = useCallback(() => {
    const iframe = document.querySelector(
      'iframe[src*="recaptcha/api2/bframe"]',
    );

    const container = iframe?.parentNode?.parentNode?.firstChild as
      | HTMLElement
      | undefined;

    if (!container) {
      return;
    }

    container.onclick = () => {
      stopLoading();

      addToast({
        title: 'Erro no CAPTCHA',
        description: 'Por favor, cumpra a etapa de verificação.',
        type: 'error',
      });
    };
  }, [stopLoading, addToast]);

  useEffect(() => {
    const domObserver = new MutationObserver(handleDOMChange);

    domObserver.observe(document.body, {
      childList: true,
    });
  }, [handleDOMChange]);

  const handleSubmit = useCallback(
    async (data: IUserLoginFormData) => {
      startLoading();
      recaptchaRef.current?.execute();

      formRef.current?.setErrors({});

      try {
        const token = await recaptchaRef.current?.executeAsync();

        if (showRecaptcha && !token) {
          addToast({
            title: 'Erro no CAPTCHA',
            description: 'Por favor, cumpra a etapa de verificação.',
            type: 'error',
          });

          stopLoading();
          return;
        }

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail inválido')
            .required('E-mail obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        await login({ email: data.email, password: data.password });

        stopLoading();

        history.push('/pagamento');
      } catch (err) {
        recaptchaRef.current?.reset();
        stopLoading();

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            title: 'Erro de validação',
            description: 'Verifique os campos com erro e tente novamente.',
            type: 'error',
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer o login, cheque as credenciais.',
        });
        setShowRecaptcha(true);
      }
    },
    [showRecaptcha, history, addToast, login, startLoading, stopLoading],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit} noValidate>
        <Input
          name="email"
          label="E-mail"
          type="email"
          defaultValue={defaultEmail}
        />
        <Input name="password" label="Senha" type="password" />

        {showRecaptcha && (
          <div style={{ color: 'red', marginBottom: '16px' }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
              size="invisible"
            />
          </div>
        )}

        <footer>
          <Forgot
            type="button"
            onClick={() =>
              addModal({
                title: 'Esqueci minha senha',
                content: <ForgotPassword />,
              })
            }
          >
            Esqueci minha senha
          </Forgot>

          <Button color="primary" type="submit">
            Continuar
          </Button>
        </footer>
      </Form>
    </Container>
  );
};

UserLogin.defaultProps = {
  defaultEmail: undefined,
};

export default UserLogin;
