import React, { ButtonHTMLAttributes } from 'react';
import { MdAutorenew } from 'react-icons/md';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  color: 'primary';
  block?: boolean;
  loading?: boolean;
  disabledMessage?: string;
};

const Button: React.FC<ButtonProps> = ({
  children,
  block = false,
  loading,
  color,
  disabledMessage,
  disabled,
  ...rest
}) => {
  return (
    <Container
      type="button"
      color={color}
      disabled={disabled || loading}
      block={block}
      {...rest}
    >
      {disabled && disabledMessage && <div>{disabledMessage}</div>}
      {loading ? (
        <span>
          <MdAutorenew size={20} />
          Carregando...
        </span>
      ) : (
        children
      )}
    </Container>
  );
};

Button.defaultProps = {
  block: false,
  loading: false,
  disabledMessage: '',
};

export default Button;
