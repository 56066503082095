import styled from 'styled-components';

interface ITabButtonProps {
  active: boolean;
}

export const TabButton = styled.button<ITabButtonProps>`
  background: none;
  border: none;
  padding: 16px 24px;
  border-bottom: 4px solid;
  font-weight: 600;
  color: ${({ active }) => (active ? 'black' : '#9e9e9e')};
  border-color: ${({ active }) => (active ? '#5142b8' : 'transparent')};
  transition: border-color 0.2s ease, color 0.2s ease;
`;
export default TabButton;
