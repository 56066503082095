import React from 'react';

import { Container, Step } from './styles';

interface IStepProgressProps {
  steps: string[];
  currentStep: number;
}

const StepProgress: React.FC<IStepProgressProps> = ({ steps, currentStep }) => {
  return (
    <Container>
      {steps.map((step, index) => (
        <Step key={+index} active={currentStep === index}>
          {step}
        </Step>
      ))}
    </Container>
  );
};

export default StepProgress;
