import styled from 'styled-components';

export const Container = styled.div`
  text-align: right;

  > form {
    > div.split {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
  }
`;

export default Container;
