import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 10, 0.77);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  > div {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    max-width: 640px;
    width: 100%;
    overflow: auto;
    position: relative;

    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
      0 0 0 1px rgb(10 10 10 / 2%);
    color: #4a4a4a;
    display: block;

    > header {
      align-items: center;
      flex-shrink: 0;
      padding: 20px;
      font-size: 24px;
      color: #363636;
      line-height: 1;

      display: flex;
      justify-content: space-between;

      > button {
        background-color: rgba(10, 10, 10, 0.2);
        border: none;
        border-radius: 50%;
        display: inline-block;
        height: 24px;
        max-height: 24px;
        max-width: 24px;
        min-height: 24px;
        min-width: 24px;
        vertical-align: top;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    > div {
      background-color: #fff;
      flex-grow: 1;
      flex-shrink: 1;
      overflow: auto;
      padding: 20px;
    }
  }
`;

export default Container;
