import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { useField } from '@unform/core';

// @ts-ignore
import Email from 'react-email-autocomplete';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
}

const customDomains = [
  'gmail.com',
  'yahoo.com',
  'yahoo.com.br',
  'hotmail.com',
  'live.com',
  'icloud.com',
  'outlook.com',
  'outlook.com.br',
  'bol.com.br',
  'uol.com.br',
  'aol.com.br',
  'terra.com.br',
];

const InputEmail: React.FC<InputProps> = ({
  label,
  name,
  disabled = false,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: any) => {
        const { value } = ref.state;
        return value;
      },
      setValue: (ref, value) => {
        if (value) {
          ref.value = value;
        }
      },
      clearValue: (ref, _) => {
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container hasError={!!error} disabled={disabled}>
      <label htmlFor={name}>
        {label}
        <div>
          <Email
            domains={customDomains}
            name={name}
            type="email"
            defaultValue={defaultValue}
            ref={inputRef}
            disabled={disabled}
            {...rest}
          />
        </div>
      </label>
      {error && (
        <Error>
          <MdErrorOutline size={16} />
          {error}
        </Error>
      )}
    </Container>
  );
};

InputEmail.defaultProps = {
  label: '',
};

export default InputEmail;
