import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 400px;
  width: 100%;

  iframe {
    margin-top: 16px;
  }

  h2 {
    color: #343f52;
  }
`;

export const PixDetails = styled.div`
  margin-top: 24px;
  text-align: center;

  > p {
    line-height: 1.5;
    text-align: left;
  }

  > img {
    margin: 0 auto;
    display: block;
    margin-top: 16px;
  }
`;

export const BilletDetails = styled.div`
  margin-top: 24px;

  > p {
    line-height: 1.5;
  }

  > div {
    text-align: center;

    > a {
      margin-top: 24px;
      display: inline-block;
      border-radius: 4px;
      padding: 0 18px;
      height: 44px;
      font-weight: 600;
      background: #5142b8;
      color: #ffffff;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      transition: background 0.2s ease;

      &:hover {
        background: ${shade(0.2, '#5142b8')};
      }
    }
  }
`;

export const CreditCardDetails = styled.div`
  margin-top: 24px;

  > p {
    line-height: 1.5;
  }

  > div {
    text-align: center;

    > a {
      margin-top: 24px;
      display: inline-block;
      border-radius: 4px;
      padding: 0 18px;
      height: 44px;
      font-weight: 600;
      background: #5142b8;
      color: #ffffff;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      transition: background 0.2s ease;

      &:hover {
        background: ${shade(0.2, '#5142b8')};
      }
    }
  }
`;

export const PaymentExt = styled.div`
  margin: 24px 0;
  position: relative;
  padding: 32px 24px 24px;
  background: #f6f6fa;
  border-radius: 8px;
  color: #343f52;

  > span {
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    top: 8px;
    left: 8px;
    color: #6c757d;
  }

  > p {
    word-break: break-all;
  }

  > button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    color: #343f52;
    background: none;
    border: none;
  }
`;

export const BoxInfo = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
  background: #2b84f3;
  color: #f4f8ff;
  line-height: 1.5;
  margin-top: 24px;
  margin-bottom: 16px;

  div {
    margin-right: 24px;
    width: 24px;
    height: 24px;
  }
`;
