import React, { useState } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { TabButton } from './styles';
import UserLogin from './UserLogin';
import Summary from '../Summary';
import UserRegistration from './UserRegistration';

const UserAccount: React.FC = () => {
  const [haveAccount, setHaveAccount] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState<string>();

  return (
    <Summary>
      <div>
        <header>
          <TabButton
            type="button"
            active={!haveAccount}
            onClick={() => setHaveAccount(false)}
          >
            Criar conta
          </TabButton>
          <TabButton
            type="button"
            active={haveAccount}
            onClick={() => setHaveAccount(true)}
          >
            Login
          </TabButton>
        </header>

        <SwitchTransition mode="out-in">
          <CSSTransition
            key={haveAccount ? 'login' : 'registration'}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            classNames="fade"
          >
            {haveAccount ? (
              <UserLogin defaultEmail={defaultEmail} />
            ) : (
              <UserRegistration
                setHaveAccount={(value: boolean) => setHaveAccount(value)}
                setDefaultEmail={(value: string) => setDefaultEmail(value)}
              />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </Summary>
  );
};

export default UserAccount;
