import React from 'react';
import { Switch, Route } from 'react-router-dom';

// import Route from './Route';
import ScrollToTop from './ScrollToTop';

import Default from '../pages/Default';
import ChargeDetails from '../pages/ChargeDetails';
import PaymentMethod from '../pages/PaymentMethod';

const Routes: React.FC = () => (
  <ScrollToTop>
    <Switch>
      <Route path="/pagamento" exact component={PaymentMethod} />
      <Route path="/obrigado" exact component={ChargeDetails} />

      <Route
        path="/cupom/:affiliate_code/:duration"
        exact
        component={Default}
      />
      <Route path="/:product_id" exact component={Default} />
      <Route path="/" component={Default} />
    </Switch>
  </ScrollToTop>
);

export default Routes;
