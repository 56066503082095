import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body, #root, html {
    min-height: 100vh
  }

  #root {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  body {
    -webkit-font-smoothing: antialiased;
    color: #6c757d;
  }

  body, input, textarea, button {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
  }

  button {
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 24px;
    border: 6px solid transparent;
    background-clip: content-box;
    transition: background-color 0.2s ease;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #b1b1b1;
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity 300ms;
  }

  .modal-enter {
    opacity: 0;
  }
  .modal-exit {
    opacity: 1;
  }
  .modal-enter-active {
    opacity: 1;
  }
  .modal-exit-active {
    opacity: 0;
  }
  .modal-enter-active,
  .modal-exit-active {
    transition: opacity 500ms;
  }
`;
