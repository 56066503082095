import React, { createContext, useCallback, useContext, useState } from 'react';

interface StepContextData {
  stepForward(): void;
  currentStep: number;
}

const StepContext = createContext<StepContextData>({} as StepContextData);

const StepProvider: React.FC = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const stepForward = useCallback(() => {
    setCurrentStep(oldStep => oldStep + 1);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StepContext.Provider value={{ stepForward, currentStep }}>
      {children}
    </StepContext.Provider>
  );
};

function useStep(): StepContextData {
  const context = useContext(StepContext);

  if (!context) {
    throw new Error('useStep must be used within a StepProvider');
  }

  return context;
}

export { StepProvider, useStep };
