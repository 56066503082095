import React, { ButtonHTMLAttributes } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import { Container } from './styles';

type IVisibilityButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isVisible: boolean;
};

const VisibilityButton: React.FC<IVisibilityButtonProps> = ({
  isVisible,
  onClick,
}) => {
  return (
    <Container type="button" onClick={onClick} tabIndex={-1}>
      {isVisible ? <MdVisibility /> : <MdVisibilityOff />}
    </Container>
  );
};

export default VisibilityButton;
