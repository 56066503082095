import React, { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { IModalProps } from '../../hooks/modal';

import Modal from './Modal';

interface IModalContainerProps {
  modals: IModalProps[];
}

const ModalContainer: React.FC<IModalContainerProps> = ({ modals }) => {
  useEffect(() => {
    if (modals.length > 0) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modals.length]);

  return (
    <TransitionGroup>
      {modals.map(modal => (
        <CSSTransition key={modal.id} timeout={500} classNames="modal">
          <Modal modal={modal} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default ModalContainer;
