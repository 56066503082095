import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  color?: 'primary';
  block: boolean;
}

const buttonColorVariations = {
  primary: css`
    background: #fd7235;
    color: #ffffff;

    &:not(:disabled):hover {
      background: ${shade(0.2, '#fd7235')};
    }
  `,
};

export const Container = styled.button<ContainerProps>`
  border: none;
  border-radius: 4px;
  padding: 0 18px;
  height: 44px;
  font-weight: 600;
  position: relative;
  display: inline-flex;
  align-items: center;
  transition: background 0.2s ease;

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }

  > div {
    position: absolute;
    border-radius: 4px;
    line-height: 1.2;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    transform: translate(-50%, -100%);
    top: -12px;
    left: 50%;
    background: black;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid black;
      transform: translateX(-50%);
    }
  }

  svg {
    margin-right: 4px;
  }

  ${props =>
    props.block &&
    css`
      width: 100%;
    `}
  ${props => buttonColorVariations[props.color || 'primary']}

  > span {
    display: inline-flex;
    align-items: center;

    svg {
      animation: rotating 1.2s linear infinite;

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export default Container;
