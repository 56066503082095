import styled from 'styled-components';

export const Container = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
`;

export default Container;
