import { FormHandles } from '@unform/core';
import axios from 'axios';
import React, { RefObject, useCallback, useEffect, useState } from 'react';
import Input from '../Input';

import { Container } from './styles';

interface IAddressProps {
  zipcode: string;
  formRef: RefObject<FormHandles>;
}

const Address: React.FC<IAddressProps> = ({ zipcode, formRef }) => {
  const [show, setShow] = useState(false);

  const [disableStreet, setDisableStreet] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableState, setDisableState] = useState(false);

  const resetAddress = useCallback(() => {
    setDisableStreet(false);
    setDisableCity(false);
    setDisableState(false);

    formRef.current?.clearField('street');
    formRef.current?.clearField('city');
    formRef.current?.clearField('state');

    setShow(false);
  }, [formRef]);

  useEffect(() => {
    let componentMounted = true;

    async function loadData() {
      if (!componentMounted) {
        return;
      }

      resetAddress();

      if (zipcode.length < 8) {
        return;
      }

      const response = await axios.get(
        `https://viacep.com.br/ws/${zipcode}/json/`,
      );

      if (response.data.erro) {
        formRef.current?.setFieldError('zipcode', 'CEP inválido');

        resetAddress();

        return;
      }

      formRef.current?.setFieldError('zipcode', '');

      const { uf: state, localidade: city, logradouro: street } = response.data;

      formRef.current?.setData({
        street: street || '',
        city: city || '',
        state: state || '',
      });

      setDisableStreet(!!street);
      setDisableCity(!!city);
      setDisableState(!!state);

      setShow(true);
    }

    loadData();

    return () => {
      componentMounted = false;
    };
  }, [zipcode, formRef, resetAddress]);

  return (
    <Container show={show}>
      <Input name="street" label="Endereço" disabled={disableStreet} />

      <Input name="city" label="Cidade" disabled={disableCity} />

      <Input name="state" label="Estado" disabled={disableState} />
    </Container>
  );
};

export default Address;
