import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface IContainerProps {
  error: boolean;
}

export const Container = styled.label<IContainerProps>`
  margin-bottom: 16px;
  display: block;
  border: 1px solid transparent;

  ${props =>
    props.error &&
    css`
      border: 1px solid red;
      padding: 0.75rem;
      border-radius: 4px;
    `}

  a {
    color: #7361cb;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      color: ${shade(0.2, '#7361cb')};
    }
  }

  input {
    display: none;

    &:checked + div::before {
      content: '✓';
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background: #7361cb;
    }
  }

  > div {
    cursor: pointer;
    margin-top: 2px;
    border: 1px solid transparent;
    border-radius: 8px;
    position: relative;
    font-size: 12px;
    padding-left: 36px;
    text-align: left;

    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 2px solid #7361cb;
    }
  }
`;

export const Error = styled.span`
  color: #e33232;
  font-size: 12px;
  margin-top: 8px;

  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    width: 16px;
  }
`;

export default Container;
