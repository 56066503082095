import React, { useEffect, useRef, useState } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { MdErrorOutline } from 'react-icons/md';
import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface Props extends InputProps {
  name: string;
  label: string;
  isPhone?: boolean;
}

const InputMask: React.FC<Props> = ({
  label,
  name,
  disabled = false,
  isPhone = false,
  mask,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [phoneMask, setPhoneMask] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value: string) {
        if (value) {
          ref.setInputValue(value);
        }
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  const handleChangePhoneMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 14) {
      setPhoneMask('(99) 9999-99999');
    } else {
      setPhoneMask('(99) 99999-9999');
    }
  };

  return (
    <Container hasError={!!error} disabled={disabled}>
      <label htmlFor={name}>
        {label}
        <div>
          <ReactInputMask
            defaultValue={defaultValue}
            ref={inputRef}
            type="text"
            disabled={disabled}
            mask={isPhone ? phoneMask : mask}
            onChange={e => isPhone && handleChangePhoneMask(e)}
            {...rest}
          />
        </div>
      </label>
      {error && (
        <Error>
          <MdErrorOutline size={16} />
          {error}
        </Error>
      )}
    </Container>
  );
};

InputMask.defaultProps = {
  isPhone: false,
};

export default InputMask;
