import React, { useEffect, useMemo, useState } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { BsCreditCard2Back } from 'react-icons/bs';

import { useHistory } from 'react-router-dom';
import Summary from '../Default/Summary';
import Billet from './Billet';
import CreditCard from './CreditCard';
import Pix from './Pix';

import { useProduct } from '../../hooks/product';

import { Container as DefaultContainer } from '../Default/styles';
import { Container, OptionButton } from './styles';
import StepProgress from '../../components/StepProgress';
import { useAuth } from '../../hooks/auth';
import FacebookPixel from '../../pixels/FacebookPixel';

const PaymentMethod: React.FC = () => {
  const [paymentMethod, setPaymentMethod] = useState(0);
  const { productLoaded, installments, setSelectedInstallments } = useProduct();
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!productLoaded || !isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated, history, productLoaded]);

  useEffect(() => {
    if (paymentMethod === 0) {
      const installmentsMaxLength = installments.length
        ? installments.length - 1
        : 0;
      setSelectedInstallments(installmentsMaxLength);
    } else {
      setSelectedInstallments(0);
    }
  }, [paymentMethod, installments, setSelectedInstallments]);

  const paymentMethodComponents = useMemo(
    () => [<CreditCard />, <Pix />, <Billet />],
    [],
  );

  return (
    <DefaultContainer>
      <FacebookPixel />
      <header>
        <img
          src="https://poxalulu-api.s3.sa-east-1.amazonaws.com/site/logo/logo-poxalulu.svg"
          alt="Poxalulu"
        />
      </header>

      <StepProgress
        steps={['Criar conta', 'Forma de pagamento', 'Informações da compra']}
        currentStep={1}
      />

      <Summary>
        <Container>
          <h2>Formas de pagamento</h2>

          <ul>
            <li>
              <OptionButton
                type="button"
                onClick={() => setPaymentMethod(0)}
                selected={paymentMethod === 0}
                className={paymentMethod === 0 ? 'selected' : ''}
              >
                <BsCreditCard2Back size={18} />
                Cartão
              </OptionButton>
            </li>
            <li>
              <OptionButton
                type="button"
                onClick={() => setPaymentMethod(1)}
                selected={paymentMethod === 1}
                className={paymentMethod === 1 ? 'selected' : ''}
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 18 18"
                  xmlSpace="preserve"
                >
                  <g id="g992" transform="translate(782.8699,645.7084)">
                    <path
                      id="path994"
                      className="st0"
                      d="M-768.8-631.9c-0.7,0-1.4-0.3-1.9-0.8l-2.7-2.7c-0.2-0.2-0.5-0.2-0.7,0l-2.7,2.7
		c-0.5,0.5-1.2,0.8-1.9,0.8h-0.5l3.4,3.4c1.1,1.1,2.8,1.1,3.9,0l3.4-3.4H-768.8z"
                    />
                  </g>
                  <g id="g996" transform="translate(666.4241,758.5073)">
                    <path
                      id="path998"
                      className="st0"
                      d="M-662.2-754.3c0.7,0,1.4,0.3,1.9,0.8l2.7,2.7c0.2,0.2,0.5,0.2,0.7,0l2.7-2.7
		c0.5-0.5,1.2-0.8,1.9-0.8h0.3l-3.4-3.4c-1.1-1.1-2.8-1.1-3.9,0l-3.4,3.4H-662.2z"
                    />
                  </g>
                  <g id="g1000" transform="translate(820.2131,724.8881)">
                    <path
                      id="path1002"
                      className="st0"
                      d="M-803-717.8l-2.1-2.1c0,0-0.1,0-0.2,0h-0.9c-0.5,0-1,0.2-1.3,0.5l-2.7,2.7
		c-0.2,0.2-0.6,0.4-0.9,0.4c-0.3,0-0.7-0.1-0.9-0.4l-2.7-2.7c-0.3-0.3-0.8-0.5-1.3-0.5h-1.2c-0.1,0-0.1,0-0.1,0l-2.1,2.1
		c-1.1,1.1-1.1,2.8,0,3.9l2.1,2.1c0,0,0.1,0,0.1,0h1.2c0.5,0,1-0.2,1.3-0.5l2.7-2.7c0.5-0.5,1.3-0.5,1.8,0l2.7,2.7
		c0.3,0.3,0.8,0.5,1.3,0.5h0.9c0.1,0,0.1,0,0.2,0l2.1-2.1C-801.9-715-801.9-716.8-803-717.8"
                    />
                  </g>
                </svg>
                <span>PIX</span>
              </OptionButton>
            </li>
            <li>
              <OptionButton
                type="button"
                onClick={() => setPaymentMethod(2)}
                selected={paymentMethod === 2}
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 486.4 486.4"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      d="M77.9,124.773c-5.799,0-10.5,4.701-10.5,10.5v211.498c0,5.799,4.701,10.5,10.5,10.5c5.799,0,10.5-4.701,10.5-10.5V135.273
		C88.4,129.474,83.699,124.773,77.9,124.773z"
                    />
                    <path
                      d="M111.372,124.773c-5.799,0-10.5,4.701-10.5,10.5v158.179c0,5.799,4.701,10.5,10.5,10.5c5.799,0,10.5-4.701,10.5-10.5
		V135.273C121.872,129.474,117.171,124.773,111.372,124.773z"
                    />
                    <path
                      d="M150.472,124.773c-5.799,0-10.5,4.701-10.5,10.5v158.179c0,5.799,4.701,10.5,10.5,10.5c5.799,0,10.5-4.701,10.5-10.5
		V135.273C160.972,129.474,156.271,124.773,150.472,124.773z"
                    />
                    <path
                      d="M311.149,124.773c-5.799,0-10.5,4.701-10.5,10.5v158.179c0,5.799,4.701,10.5,10.5,10.5c5.799,0,10.5-4.701,10.5-10.5
		V135.273C321.649,129.474,316.948,124.773,311.149,124.773z"
                    />
                    <path
                      d="M230.995,124.773c-5.799,0-10.5,4.701-10.5,10.5v158.179c0,5.799,4.701,10.5,10.5,10.5s10.5-4.701,10.5-10.5V135.273
		C241.495,129.474,236.794,124.773,230.995,124.773z"
                    />
                    <path
                      d="M274.123,124.773c-5.799,0-10.5,4.701-10.5,10.5v158.179c0,5.799,4.701,10.5,10.5,10.5c5.799,0,10.5-4.701,10.5-10.5
		V135.273C284.623,129.474,279.922,124.773,274.123,124.773z"
                    />
                    <path
                      d="M198.091,124.773c-5.799,0-10.5,4.701-10.5,10.5v158.179c0,5.799,4.701,10.5,10.5,10.5c5.799,0,10.5-4.701,10.5-10.5
		V135.273C208.591,129.474,203.89,124.773,198.091,124.773z"
                    />
                    <path
                      d="M361.675,124.773c-5.799,0-10.5,4.701-10.5,10.5v158.179c0,5.799,4.701,10.5,10.5,10.5c5.799,0,10.5-4.701,10.5-10.5
		V135.273C372.175,129.474,367.474,124.773,361.675,124.773z"
                    />
                    <path
                      d="M411.083,124.773c-5.799,0-10.5,4.701-10.5,10.5v211.498c0,5.799,4.701,10.5,10.5,10.5s10.5-4.701,10.5-10.5V135.273
		C421.583,129.474,416.882,124.773,411.083,124.773z"
                    />
                    <path
                      d="M455.211,62.346L31.188,62.346C13.991,62.346,0,76.337,0,93.535v299.33c0,17.197,13.991,31.189,31.188,31.189l424.023,0
		c17.197,0,31.189-13.991,31.189-31.189V93.535C486.4,76.338,472.409,62.346,455.211,62.346z M465.4,392.865
		c0,5.618-4.57,10.189-10.189,10.189l-424.023,0c-5.618,0-10.188-4.571-10.188-10.189V93.535c0-5.618,4.57-10.189,10.188-10.189
		l424.023,0.001c5.618,0,10.189,4.571,10.189,10.188V392.865z"
                    />
                  </g>
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                </svg>
                Boleto
              </OptionButton>
            </li>
          </ul>

          <SwitchTransition mode="out-in">
            <CSSTransition
              key={['pix', 'billet', 'credit_card'][paymentMethod]}
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false);
              }}
              classNames="fade"
            >
              {paymentMethodComponents[paymentMethod]}
            </CSSTransition>
          </SwitchTransition>
        </Container>
      </Summary>

      <footer>
        <span className="contact">
          Em caso de problemas, entre em contato conosco enviando um e-mail para{' '}
          <a href="mailto:suporte@poxalulu.com.br">suporte@poxalulu.com.br</a>.
        </span>

        <div>
          <span>©2022 Poxalulu - Todos os Direitos Reservados.</span>

          <span>
            Desenvolvido por{' '}
            <a
              href="https://www.pxtecnologia.com.br"
              target="_blank"
              rel="noreferrer"
            >
              PX Tecnologia
            </a>
            .
          </span>
        </div>
      </footer>
    </DefaultContainer>
  );
};

export default PaymentMethod;
