import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import api from '../services/api';
import { useLoading } from './loading';

export interface IProductData {
  id: string;
  name: string;
  duration_in_months: number;
  price: number;
  wordings_per_month: number;
  discount: number;
  priceWithoutDiscount: number;
}

interface IInstallments {
  label: string;
  value: number;
}

interface IProductContextData {
  product: IProductData | null;
  productLoaded: boolean;
  coupon_code: string | null;
  affiliate_code: string | null;
  installments: IInstallments[];
  getTotal(): string;
  setSelectedInstallments(value: number): void;
}

const ProductContext = createContext<IProductContextData>(
  {} as IProductContextData,
);

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ProductProvider: React.FC = ({ children }) => {
  const { startLoading, stopLoading } = useLoading();
  const history = useHistory();

  const query = useQuery();

  const [product, setProduct] = useState<IProductData | null>(null);
  const [productLoaded, setProductLoaded] = useState(false);
  const [installments, setInstallments] = useState<IInstallments[]>([]);
  const [selectedInstallments, setSelectedInstallments] = useState(11);
  const [coupon_code, setCouponCode] = useState<string | null>(null);
  const [affiliate_code, setAffiliateCode] = useState<string | null>(null);

  useEffect(() => {
    async function loadData() {
      try {
        if (productLoaded) {
          return;
        }

        const splittedPathName = location.pathname.slice(1).split('/');

        const [, affiliateCode, duration] = splittedPathName;

        const product_id =
          splittedPathName[0] === 'cupom' ? null : splittedPathName[0];

        let resAffiliateCode;
        let resCouponCode;
        let resProductId;
        if (product_id) {
          startLoading();

          const response = await api.get(`/products/${product_id}`, {
            params: {
              affiliate: affiliateCode,
              coupon_code: query.get('coupon'),
              affiliate_code: query.get('affiliate_code'),
            },
          });

          resAffiliateCode = query.get('affiliate_code');
          resCouponCode = query.get('coupon');
          resProductId = response.data.id;

          setProduct(response.data);
          setCouponCode(resCouponCode);
          setAffiliateCode(resAffiliateCode);
          stopLoading();
          setProductLoaded(true);
        } else if (affiliateCode && duration) {
          startLoading();

          const response = await api.get('/products/featured', {
            params: {
              duration,
              affiliate_code: affiliateCode,
            },
          });

          resAffiliateCode = affiliateCode;
          resCouponCode = response.data.coupon_code;
          resProductId = response.data.product.id;

          setProduct(response.data.product);
          setCouponCode(resCouponCode);
          setAffiliateCode(resAffiliateCode);
          stopLoading();
          setProductLoaded(true);
        }

        if (resProductId) {
          let response = await api.get(
            `/products/${resProductId}/installments`,
            {
              params: {
                coupon: resCouponCode,
                affiliate: resAffiliateCode,
              },
            },
          );

          setInstallments(response.data);

          if (resCouponCode) {
            response = await api.get(`/coupons/check`, {
              params: {
                code: resCouponCode,
                product_id: resProductId,
              },
            });

            setProduct(oldProduct => {
              if (!oldProduct) {
                return null;
              }

              return {
                ...oldProduct,
                price:
                  oldProduct.price -
                  Math.trunc((oldProduct.price * response.data.discount) / 100),
              };
            });
          }

          if (resAffiliateCode) {
            response = await api.get(`/affiliates/check`, {
              params: {
                code: resAffiliateCode,
              },
            });

            if (response.data.discount_type === 'flat') {
              setProduct(oldProduct => {
                if (!oldProduct) {
                  return null;
                }

                return {
                  ...oldProduct,
                  price: oldProduct.price - response.data.discount_amount,
                };
              });
            } else {
              setProduct(oldProduct => {
                if (!oldProduct) {
                  return null;
                }

                return {
                  ...oldProduct,
                  price:
                    oldProduct.price -
                    Math.trunc(
                      (oldProduct.price * response.data.discount_amount) / 100,
                    ),
                };
              });
            }
          }
        }

        history.push('/');
      } catch (err) {
        stopLoading();
      }
    }

    loadData();
  }, [query, history, productLoaded, startLoading, stopLoading]);

  const getTotal = useCallback(() => {
    if (installments.length === 0) {
      return '';
    }

    return installments[selectedInstallments].label;
  }, [installments, selectedInstallments]);

  const contextValues = useMemo(
    () => ({
      product,
      productLoaded,
      installments,
      affiliate_code,
      coupon_code,
      setSelectedInstallments,
      getTotal,
    }),
    [
      product,
      affiliate_code,
      coupon_code,
      productLoaded,
      installments,
      setSelectedInstallments,
      getTotal,
    ],
  );

  return (
    <ProductContext.Provider value={contextValues}>
      {children}
    </ProductContext.Provider>
  );
};

function useProduct(): IProductContextData {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error('useProduct must be used within a ProductProvider');
  }

  return context;
}

export { ProductProvider, useProduct };
