import styled from 'styled-components';

interface IOptionButtonProps {
  selected: boolean;
}

export const Container = styled.div`
  max-width: 400px;
  width: 100%;

  h2 {
    color: #343f52;
  }

  > ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
    gap: 0.5rem;

    > li {
      width: 100%;

      > button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 52px;
        padding: 0 0.5rem;

        &.selected {
          color: #fff;
        }

        > img {
          width: 64px;
        }

        > svg {
          margin-right: 0.5rem;
          width: 18px;
        }
      }
    }
  }
`;

export const OptionButton = styled.button<IOptionButtonProps>`
  display: flex;
  padding: 18px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ selected }) => (selected ? '#fff' : '#5142b8')};
  background: ${({ selected }) => (selected ? '#5142b8' : '#f6f6fa')};
  border: none;
  border-radius: 4px;

  svg {
    fill: ${({ selected }) => (selected ? '#fff' : '#5142b8')};
  }
`;

export default Container;
