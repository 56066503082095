import styled, { css } from 'styled-components';
import ReactSelect, { OptionTypeBase, Props } from 'react-select';
import { shade } from 'polished';

interface ContainerProps {
  hasError: boolean;
}

export const Container = styled.div<ContainerProps>`
  text-align: left;
  margin-bottom: 16px;
  > label {
    display: flex;
    flex-direction: column;
    color: #595959;
    > div {
      border-radius: 4px;
      border: 1px solid #9e9e9e;
      background: #fff;
      padding: 12px 16px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      ${props =>
        props.hasError &&
        css`
          border-color: #e33232;
          &:not(:focus-within) {
            input {
              color: #e33232;
            }
          }
        `}
      &:hover,
      &:focus-within {
        border-color: 1px solid #9e9e9e;
      }
      > svg {
        margin-right: 16px;
        color: #757575;
      }
    }
  }
`;

export const ReactSelectElement = styled(ReactSelect)<Props<OptionTypeBase>>`
  flex: 1;
  background: transparent;
  color: #595959;
  > div {
    border: none;
    background: none;
    min-height: 0;
    .react-select__value-container {
      padding: 0;
      .react-select__placeholder {
        color: #919191;
        font-weight: normal;
        margin-left: 0;
        margin-right: 0;
      }
      .react-select__single-value {
        font-weight: normal;
        color: #4d4d4d;
      }
      .css-b8ldur-Input {
        color: #4d4d4d;
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .react-select__option {
      font-weight: normal;
    }
    .react-select__option:hover,
    .react-select__option--is-focused,
    .react-select__option--is-selected {
      background: ${shade(0.02, '#ffffff')};
      color: inherit;
    }
    .react-select__indicators {
      .react-select__indicator-separator {
        display: none;
      }
      .react-select__indicator {
        color: #4d4d4d;
        padding: 0;
      }
    }
  }
  &::placeholder {
    color: #919191;
  }
  .react-select__menu {
    width: Calc(100% + 32px);
    left: -16px;
    top: Calc(100% + 12px);
    background: #ffffff;
    box-shadow: rgba(183, 192, 206, 0.4) 0px 5px 20px;
  }
  .react-select__menu-notice {
    font-weight: normal;
  }
  .css-1pahdxg-control {
    box-shadow: none;
  }
`;

export const Error = styled.div`
  color: #e33232;
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  > svg {
    margin-right: 8px;
  }
`;
