import styled from 'styled-components';

export const Container = styled.div`
  text-align: right;

  > form {
    > img {
      width: 100%;
      margin: 16px 0;
    }

    > div.split {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
  }
`;

export default Container;
