import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 64px;

  @media (max-width: 991.98px) {
    flex-direction: column-reverse;
    margin: 0 auto;
    margin-bottom: 64px;

    > aside {
      margin-bottom: 24px;

      ul,
      h2 {
        display: none;
      }
    }
  }

  > div {
    max-width: 400px;
    width: 100%;

    > header {
      margin-bottom: 24px;
    }
  }

  > aside {
    max-width: 400px;
    width: 100%;

    > div {
      background: #f6f6fa;
      border-radius: 8px;
      padding: 32px;

      h2 {
        margin-bottom: 32px;
        color: #343f52;
        font-weight: bold;
        font-size: 24px;
        font-weight: 600;
      }

      > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        > span {
          color: #5142b8;
        }
      }

      > ul {
        list-style: none;
        margin-bottom: 40px;

        li {
          margin-bottom: 8px;

          > svg {
            color: #01bc82;
            margin-right: 8px;
          }
        }
      }

      > footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        font-size: 14px;

        > div {
          color: #343f52;

          > small {
            font-weight: bold;
            margin-right: 4px;
          }

          > span {
            font-weight: bold;
            font-size: 24px;
          }
        }
      }
    }
  }
`;

export default Container;
