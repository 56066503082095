import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import GlobalStyle from './styles/global';

import AppProvider from './hooks';

import Routes from './routes';
import { ProductProvider } from './hooks/product';

const App = () => {
  return (
    <BrowserRouter>
      <AppProvider>
        <ProductProvider>
          <Routes />
          <GlobalStyle />
        </ProductProvider>
      </AppProvider>
    </BrowserRouter>
  );
};

export default App;
