import styled, { css } from 'styled-components';

interface ContainerProps {
  hasError: boolean;
  disabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  text-align: left;
  margin-bottom: 16px;

  label {
    display: flex;
    flex-direction: column;
    color: #5e5e5e;

    > div {
      border-radius: 4px;
      border: 1px solid #9e9e9e;
      background: #fff;
      padding: 12px 16px;
      margin-top: 8px;

      display: flex;
      align-items: center;

      ${props =>
        props.disabled &&
        css`
          opacity: 0.45;
          cursor: not-allowed;

          input {
            cursor: not-allowed;
          }
        `}

      ${props =>
        props.hasError &&
        css`
          border-color: #e33232;

          &:not(:focus-within) {
            input {
              color: #e33232;
            }
          }
        `}

      &:hover,
      &:focus-within {
        border-color: 1px solid #9e9e9e;
      }

      > div {
        width: 100%;
      }

      input {
        flex: 1;
        height: 19px;
        width: 100%;
        background: transparent;
        border: none;
        color: #5e5e5e;

        &::placeholder {
          color: #919191;
        }

        &[type='date']::-webkit-calendar-picker-indicator {
          display: none;
        }
      }
    }
  }
`;

export const Error = styled.div`
  color: #e33232;
  font-size: 14px;
  margin-top: 4px;

  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    width: 16px;
  }
`;
