import { useField } from '@unform/core';
import React, { ReactElement, useEffect, useRef } from 'react';
import { MdErrorOutline } from 'react-icons/md';

import { Container, Error } from './styles';

interface CheckboxProps {
  name: string;
  label: string | ReactElement;
  checked: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  checked,
  onChange,
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: HTMLInputElement) => {
        return ref.checked;
      },
      setValue: (ref: HTMLInputElement, value) => {
        ref.checked = value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container error={!!error}>
      <input
        id={name}
        defaultValue={defaultValue}
        ref={inputRef}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <div>{label}</div>
      {error && (
        <Error>
          <MdErrorOutline size={16} />
          {error}
        </Error>
      )}
    </Container>
  );
};

export default Checkbox;
