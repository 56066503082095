import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

interface IChargeData {
  payment_ext: string;
  type: 'pix' | 'credit_card' | 'billet';
  boleto_url?: string;
  qr_code?: string;
  qr_code_url?: string;
  boleto_expiration_date?: string;
}

interface ICreateCharge {
  payment_ext: string;
  type: 'pix' | 'credit_card' | 'billet';
  boleto_url?: string;
  qr_code?: string;
  qr_code_url?: string;
  boleto_expiration_date?: string;
}

interface ChargeContextState {
  charge: IChargeData;
  createCharge(data: ICreateCharge): void;
}

const ChargeContext = createContext<ChargeContextState>(
  {} as ChargeContextState,
);

const ChargeProvider: React.FC = ({ children }) => {
  const [charge, setCharge] = useState({} as IChargeData);

  const createCharge = useCallback((data: ICreateCharge) => {
    setCharge(data);
  }, []);

  const chargeValues = useMemo(
    () => ({ charge, createCharge }),
    [charge, createCharge],
  );

  return (
    <ChargeContext.Provider value={chargeValues}>
      {children}
    </ChargeContext.Provider>
  );
};

function useCharge(): ChargeContextState {
  const context = useContext(ChargeContext);

  if (!context) {
    throw new Error('useCharge must be user within an ChargeProvider');
  }

  return context;
}

export { ChargeProvider, useCharge };
