import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { v4 } from 'uuid';

import ModalContainer from '../components/ModalContainer';

export interface IModalProps {
  id: string;
  title: string;
  content: React.ReactNode | string;
}

interface ModalContextData {
  addModal(message: Omit<IModalProps, 'id'>): void;
  removeModal(id: string): void;
  clearModals(): void;
}

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

const ModalProvider: React.FC = ({ children }) => {
  const [modals, setModals] = useState<IModalProps[]>([]);

  const addModal = useCallback(
    ({ title, content }: Omit<IModalProps, 'id'>) => {
      const id = v4();

      const modal = {
        id,
        title,
        content,
      };

      setModals(state => [...state, modal]);
    },
    [],
  );

  const removeModal = useCallback((id: string) => {
    setModals(state => state.filter(modal => modal.id !== id));
  }, []);

  const clearModals = useCallback(() => {
    setModals([]);
  }, []);

  const toastContextValues = useMemo(
    () => ({ addModal, removeModal, clearModals }),
    [addModal, removeModal, clearModals],
  );

  return (
    <ModalContext.Provider value={toastContextValues}>
      {children}
      <ModalContainer modals={modals} />
    </ModalContext.Provider>
  );
};

function useModal(): ModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
}

export { ModalProvider, useModal };
