import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.main`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  flex: 1;

  > header {
    padding-top: 16px;
    text-align: center;

    img {
      width: 100%;
      max-width: 177px;
    }
  }

  > footer {
    margin-top: auto;

    a {
      color: #7361cb;
      text-decoration: none;
      transition: color 0.2s ease;

      &:hover {
        color: ${shade(0.2, '#7361cb')};
      }
    }

    > span.contact {
      text-align: center;
      font-size: 14px;
      margin-bottom: 40px;
      display: block;
    }

    > div {
      position: relative;
      padding: 40px 0;
      display: flex;
      justify-content: space-between;
      font-size: 12px;

      @media (max-width: 767.98px) {
        flex-direction: column;
        text-align: center;

        > span + span {
          margin-top: 16px;
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        top: 0;
        background-color: transparent;
        background-image: linear-gradient(
          90deg,
          rgba(206, 211, 246, 0) 0,
          #bbb 38%,
          #bbb 64%,
          rgba(206, 211, 246, 0) 99%
        );
        opacity: 0.3;
      }
    }
  }
`;

export default Container;
