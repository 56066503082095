export default function getPagarmeErrors(
  errors: any,
  type: 'array' | 'object',
): string {
  let errorMessage = '';

  if (type === 'array') {
    errors['request.card'].forEach((error: string) => {
      errorMessage += error;
    });
  } else {
    errors.message.forEach((error: { message: string }) => {
      errorMessage += error.message;
    });
  }

  return errorMessage;
}
