import styled from 'styled-components';

interface IContainerProps {
  show: boolean;
}

export const Container = styled.div<IContainerProps>`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  position: ${props => (props.show ? 'relative' : 'absolute')};

  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 54px;
`;

export default Container;
