import React from 'react';

import { StepProvider } from './steps';
import { ToastProvider } from './toast';
import { AuthProvider } from './auth';
import { LoadingProvider } from './loading';
import { ChargeProvider } from './charge';
import { ModalProvider } from './modal';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <LoadingProvider>
      <ChargeProvider>
        <StepProvider>
          <ToastProvider>
            <ModalProvider>{children}</ModalProvider>
          </ToastProvider>
        </StepProvider>
      </ChargeProvider>
    </LoadingProvider>
  </AuthProvider>
);

export default AppProvider;
